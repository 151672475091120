import { OCI__API_REACH_NET_NZ } from "../../config/local";
import store from "../../store";
import { formatDateForApi } from "../dateHelper";
import { plainWarning } from "../notifications/apiWrapper";
import { apiErrorEvent } from "./customEvents";
import { bracketKey } from "../weight";

const cache = [];

export const networkManagementQuote = async () => {
    let res, data, cacheKey;
    const defaultRates = {
        T1: 0.0,
        T2: 0.0,
        T3: 0.0,
    };

    try {
        const customerCmsCode = store.getState().quoteMeta.customerCmsCode;
        const campaignStartDate = store.getState().quoteMeta.campaignStartDate;
        const campaignStartDateFormatted = formatDateForApi(campaignStartDate);

        const printConfig = store.getState().printingSpecs;
        const quoteDetails = store.getState().quoteDetails;

        // respect custom weight bracket choice
        const weightRange = quoteDetails.customWeightBracket
            ? quoteDetails.customWeightBracket
            : bracketKey(quoteDetails.weight * printConfig.pageCount);

        cacheKey = `${customerCmsCode}-${campaignStartDateFormatted}`;

        if (cache[cacheKey]) {
            return cache[cacheKey];
        }

        const apiUrl = new URL(`${OCI__API_REACH_NET_NZ}/rates/v1/network-management`);

        customerCmsCode
            ? apiUrl.searchParams.append("cms_acct_code", customerCmsCode)
            : null;
        campaignStartDate
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDateFormatted
              )
            : null;

        apiUrl.searchParams.append("weight_range", Number(weightRange));

        res = await fetch(apiUrl);
    } catch (e) {
        plainWarning("Network issues!", e);
        console.error(e);
        return defaultRates;
    }

    try {
        if (res.ok) {
            data = await res.json();
        } else {
            document.dispatchEvent(
                apiErrorEvent({
                    errorMsg:
                        "Network fee calulation failed, please try again.",
                })
            );
            return defaultRates;
        }
    } catch (e) {
        console.error(e);
        return defaultRates;
    }

    if (data.error) {
        console.error(data.error);
        return defaultRates;
    }

    if (Object.keys(data.rates).length < 3) {
        console.error(
            "No rates returned. Check the customer rate card is set up correctly."
        );
        return defaultRates;
    }

    cache[cacheKey] = data.rates;
    return data.rates;
};
