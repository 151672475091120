export const URL_CLIENT_VIEW = "https://sputnik-view.reach-demo.com/";

export const URL_CX_VIEW = "https://sputnik.reach-demo.com/";

export const URL_GEO_API = "https://geo-dot-dev-map-tools.ts.r.appspot.com";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyCAgAVdOzA3ADL5_pW-YipERGn4Z4YDlLc",
    authDomain: IS_CLIENT_APP
        ? "sputnik-view.reach-demo.com"
        : "sputnik.reach-demo.com",
    databaseURL: "https://dev-map-tools.firebaseio.com",
    projectId: "dev-map-tools",
    storageBucket: "dev-map-tools.appspot.com",
    messagingSenderId: "622295727026",
    appId: "1:622295727026:web:0d2668472ee07d1c",
};

export const CUSTOM_OAUTH_PARAMETERS = {
    hd: "reach.nz",
};

export const QUOTE_PDF_TRIGGER_URL = "https://hook.integromat.com/w6f6gx2lr5ksgczdo59h0jaklhei28sy";

export const QUOTE_BOOKING_URL =  "https://quote-booking.reach-demo.com/quotes";

export const LIBERTY_JOB_URL =    "https://ga31b0306967e33-libertydev.adb.ap-sydney-1.oraclecloudapps.com/ords/f?p=100:101:::::P101_JOB_ID:";

export const OCI__API_REACH_NET_NZ = "https://dev-api.reach.net.nz";
