import { OCI__API_REACH_NET_NZ } from "../../config/local";
import store from "../../store";
import { formatDateForApi } from "../dateHelper";
import { plainWarning } from "../notifications/apiWrapper";
import { financialNumberFormat } from "../numberHelper";
import { apiErrorEvent } from "./customEvents";

export const totalQuote = async () => {
    const {
        volumeCost,
        freightCost,
        vfrCost,
        hasFreight,
        printCost,
        networkManagementCost,
        targetingCost,
        adminFee,
        dairyFarmerPremium,
        printingLine,
        extraCopiesFee,
    } = store.getState().quoteDetails;

    const customerCmsCode = store.getState().quoteMeta.customerCmsCode;
    const campaignStartDate = store.getState().quoteMeta.campaignStartDate;
    const campaignStartDateFormatted = formatDateForApi(campaignStartDate);
    const promoCode = (store.getState().quoteMeta.promoCode ?? "").trim();

    const params = {
        volumeCost: financialNumberFormat(volumeCost),
        freightCost: financialNumberFormat(
            hasFreight ? freightCost + (vfrCost || 0) : 0
        ),
        printCost: financialNumberFormat(printingLine ? printCost : 0),
        networkManagementCost: financialNumberFormat(networkManagementCost),
        targetingCost: financialNumberFormat(targetingCost),
        adminFee: financialNumberFormat(adminFee),
        dairyFarmerPremium: financialNumberFormat(dairyFarmerPremium),
        extraCopiesFee: financialNumberFormat(extraCopiesFee),
        ...(customerCmsCode && { cms_acct_code: customerCmsCode }),
        ...(campaignStartDate && { start_date: campaignStartDateFormatted }),
        ...(promoCode && { promoCode: promoCode.toUpperCase() }),
    };

    const url = `${OCI__API_REACH_NET_NZ}/calculator/v1/total?${new URLSearchParams(
        params
    )}`;

    try {
        const response = await fetch(encodeURI(url));
        if (response.ok) {
            return await response.json();
        }

        document.dispatchEvent(
            apiErrorEvent({
                errorMsg: "Total calulation failed, please try again.",
            })
        );
    } catch (error) {
        plainWarning("Network issues!", error);
        console.error(error);
    }
};
