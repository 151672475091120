import { clientUnsafeWarningEvent } from "../../components/toast";
import { OCI__API_REACH_NET_NZ } from "../../config/local";
import store from "../../store";
import { formatDateForApi } from "../dateHelper";
import { bracketKey } from "../weight";

const defaultRate = { rate: 0, amount: 0 };

export const farmerPremiumQuote = async (volume) => {
    if (volume === 0) return defaultRate;

    let response, quote;

    const customerCmsCode = store.getState().quoteMeta.customerCmsCode;
    const campaignStartDate = store.getState().quoteMeta.campaignStartDate;
    const campaignStartDateFormatted = formatDateForApi(campaignStartDate);

    const { customWeightBracket, weight } = store.getState().quoteDetails;
    const { pageCount } = store.getState().printingSpecs;
    // respect custom weight bracket choice to override weight/bracket calculations
    const weightBracket = customWeightBracket ?? bracketKey(weight * pageCount);

    const apiUrl = new URL(`${OCI__API_REACH_NET_NZ}/rates/v1/farmer-premium`);

    try {
        customerCmsCode
            ? apiUrl.searchParams.append("cms_acct_code", customerCmsCode)
            : null;
        campaignStartDate
            ? apiUrl.searchParams.append(
                  "start_date",
                  campaignStartDateFormatted
              )
            : null;
        weightBracket
            ? apiUrl.searchParams.append("weight_range", weightBracket)
            : null;
        apiUrl.searchParams.append("qty", volume);

        response = await fetch(apiUrl);

        if (!response.ok) {
            global.document.dispatchEvent(
                clientUnsafeWarningEvent({
                    message: `Failed to load the farmer premium quote${
                        customerCmsCode
                            ? " with customer account number " + customerCmsCode
                            : ""
                    }.`,
                })
            );
            return defaultRate;
        }
    } catch (error) {
        global.document.dispatchEvent(
            clientUnsafeWarningEvent({
                message: `Failed to load the farmer premium quote${
                    customerCmsCode
                        ? " with customer account number " + customerCmsCode
                        : ""
                }.`,
            })
        );
        console.warn("Network issues!", error);
        return defaultRate;
    }

    try {
        quote = await response.json();

        if (quote.farmer_premium?.rate && quote.farmer_premium?.amount) {
            return {
                rate: quote.farmer_premium.rate,
                amount: quote.farmer_premium.amount,
            };
        }

        global.document.dispatchEvent(
            clientUnsafeWarningEvent({
                message: "Unexpected farmer premium quote value.",
            })
        );
        console.warn(`Quote value: [${JSON.stringify(quote)}]`);
    } catch (error) {
        global.document.dispatchEvent(
            clientUnsafeWarningEvent({
                message: "Unexpected farmer premium quote structure.",
            })
        );
        console.warn(`Unexpected quote structure: [${quote}]`);
    }
    return defaultRate;
};
