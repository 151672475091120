import { cloneDeep } from "lodash-es";
import { getIdToken } from "firebase/auth";

import { OCI__API_REACH_NET_NZ } from "../config/local";
import store from "../store";
import { auth } from "./firebase/init";

import { clientInfoNoDelayEvent, clientWarningNoDelayEvent } from "../components/toast";


// this will invoke PUT APU endpoint which will cause either 
// updating or creating a new catchment store plan
// the payload twemplate should be taken from the state `catchmentPlan` 
// and filled with the data merged from the other parts of the state
export const saveCatchmentPlan = async () => {

    const quoteMeta = store.getState().quoteMeta;
    // const quoteDetails = store.getState().quoteDetails;
    const catchmentPlan = store.getState().catchmentPlan;
    const territories = store.getState().territories;

    // break out if this is not a catchment plan
    if (!catchmentPlan) {
        return false;
    }

    // do basic checks for quote ID, store ID and customer CMS id
    if (!quoteMeta.id) {
        global.document.dispatchEvent(clientWarningNoDelayEvent({message: "The Quote ID is missing, try saving the quote first."}));
        return false;
    }

    if (!catchmentPlan.store_id || !catchmentPlan.store_name) {
        global.document.dispatchEvent(clientWarningNoDelayEvent({message: "The Store ID or Name is missing, please provide it."}));
        return false;
    }

    if (!quoteMeta.customer.cmsAcctCode) {
        global.document.dispatchEvent(clientWarningNoDelayEvent({message: "The Customer CMS ID is missing, please provide it."}));
        return false;
    }

    // construct payload for the API off the catchmentPlan state
    const payload = cloneDeep(catchmentPlan);
    // store ID and store Name must be already set in the catchmentPlan state
    payload.cms_acct_code = quoteMeta.customer.cmsAcctCode;
    payload.configuration.sputnik_quote_id = quoteMeta.id;

    // collecte all territories and their active rounds filtered by network
    // an individual plan will be represented by territories and their rounds and this is the shape of the object
    /**
    {
      "plan_id": "rm_6b63996a-ccaf-8def-3821-49e996b540bd",
      "plan_name": "Plan 31",
      "channel_selections": {
        "URBAN": [
          "EXCLUSIVE"
        ],
        "POBOXES":["FARMER","RESIDENTIAL"],
        "COUNTERS":["RESIDENTIAL"]
      },
      "rounds": [
        11535,
        7047,
        19432,
        19430,
        14234
      ]
    }
    */
    payload.plans = territories
        .map(t => {
            // fetch enabled rounds in selected network(s)
            const rounds = Object.keys(t.rounds).filter(round_id => 
                t.rounds[round_id].selected && t.networkSelections.includes(t.rounds[round_id].layer)
            );

            // similarly build channels selections for the plan based on selected networks
            const volume_selections = cloneDeep(t.volumeSelections);
            const channel_selections = Object.keys(volume_selections).reduce((acc, network) => {
                if (t.networkSelections.includes(network)) {
                    acc[network] = volume_selections[network];
                }
                return acc;
            }, {});

            return {
                plan_id: t.id,
                plan_name: t.location?.formatted?.main_text || `Plan`,
                channel_selections,
                rounds
            };
        }); 
    
    // use the V1 endpoint with `/quote-builder/catchment` path and PUT method
    // the payload should be sent as JSON
    // response will be 204 on success
    // handle the response and show a toast message to the user
    // in case of an error show an error toast message
    // return true on success and false on error
    let auth_token;
    try {
        auth_token = await getIdToken(auth.currentUser);
    } catch (error) {
        console.error("Failed to get token", error);
        global.document.dispatchEvent(clientWarningNoDelayEvent({message: "Failed to save the catchment plan, please reload the page and try again."}));
        return false;
    }

    try {
        const res = await fetch(`${OCI__API_REACH_NET_NZ}/quote-builder/v1/catchment`, {
            method: "PUT",
            headers: {
                "Firebase-Token": `Bearer ${auth_token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        if (res.ok) {
            global.document.dispatchEvent(clientInfoNoDelayEvent({message: "Catchment plan saved successfully."}));
            return true;
        } else {
            global.document.dispatchEvent(clientWarningNoDelayEvent({message: "Failed to save the catchment plan, please try again."}));
            return false;
        }
    } catch (error) {
        global.document.dispatchEvent(clientWarningNoDelayEvent({message: "Failed to save the catchment plan, please try again."}));
        return false;
    }
    
};