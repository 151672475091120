import { LitElement, html } from "lit-element";
import { nothing } from "lit-html";
import { subscribe, unsubscribe } from "redux-subscribe";
import { URL_CLIENT_VIEW } from "../config/local";
import { noop, quoteDetailsUpdate, quoteMetaUpdate } from "../core/actions/actions";
import { dayMonthYear, isDatePast } from "../services/dateHelper";
import { hasTargetingVariables, isQuoteLocked, isQuoteTargeted } from "../services/quoteHelper";
import { bracketTitle, calcPageWeight, weightBrackets } from "../services/weight";
import { openDialog } from "./dialog";
import store from "../store";
import circleCheckIcon from "./svg/checkmark-circle";
import linkIcon from "./svg/link";
import penIcon from "./svg/pen";
import copyIcon from "./svg/copy";
import { copyToClipboard } from "../services/clipboard";
import { isNetworkDistributed } from "../core/selectors/sliceSelector";
import { GMAP_ROUNDS_LAYERS } from "../const/dataLayers";
const POSTIES = GMAP_ROUNDS_LAYERS.POSTIES;
const POBOXES = GMAP_ROUNDS_LAYERS.POBOXES;

export class QuoteDetailsPanel extends LitElement {
    constructor() {
        super();
        this.quote = store.getState().quoteDetails;
        this.meta = store.getState().quoteMeta;
        this.targetingFilters = store.getState().targetingFilters;
        this.printingSpecs = store.getState().printingSpecs;

        store.dispatch(
            subscribe("quoteMeta", "updateMetaQuoteDetailsPanel", (data) => {
                this.meta = data.next;
                this.requestUpdate();
                return noop("updateMetaQuoteDetailsPanel");
            })
        );
        store.dispatch(
            subscribe("quoteDetails", "updateDetailsQuoteDetailsPanel", (data) => {
                this.quote = data.next;
                this.requestUpdate();
                return noop("updateDetailsQuoteDetailsPanel");
            })
        );
        store.dispatch(
            subscribe("quoteDetails.subtotalMatrix", "updateSubtotalMatrixPanel2", (data) => {
                this.quote = data.next;
                this.requestUpdate();
                return noop("updateSubtotalMatrixPanel2");
            })
        );
        store.dispatch(
            subscribe("printingSpecs", "updatePrintingSpecsQuoteDetailsPanel", (data) => {
                this.printingSpecs = data.next;
                this.requestUpdate();
                return noop("updatePrintingSpecsQuoteDetailsPanel");
            })
        );
        store.dispatch(
            subscribe("targetingFilters", "updateTargetingFiltersQuoteDetailsPanel", (data) => {
                this.targetingFilters = data.next;

                if (hasTargetingVariables(data.next)) {
                    this.toggleTargetedValue(true);
                }
                this.requestUpdate();
                return noop("updateTargetingFiltersQuoteDetailsPanel");
            })
        );
    }

    static get properties() {
        return {
            quote: { type: Object },
            quoteMeta: { type: Object },
            targetingFilters: { type: Object },
            printingSpecs: { type: Object },
        };
    }

    weightBracket() {
        const pageWeight = calcPageWeight(
            this.printingSpecs.paperSize,
            this.printingSpecs.gsm
        );
        return bracketTitle(pageWeight * this.printingSpecs.pageCount);
    }

    mailerDetailsDialog() {
        openDialog("rm-printing-settings");
    }

    disconnectedCallback() {
        store.dispatch(unsubscribe("quoteMeta", "updateMetaQuoteDetailsPanel"));
        store.dispatch(unsubscribe("quoteDetails", "updateDetailsQuoteDetailsPanel"));
        store.dispatch(unsubscribe("targetingFilters", "updateTargetingFiltersQuoteDetailsPanel"));
    }

    toggleTargetedValue(value) {
        store.dispatch(quoteDetailsUpdate({ targeted: value }));
    }

    renderLockedDetails() {
        return html` <section class="flex flex-row flex-grow-0">
            <div class="flex flex-col my-1 gap-1">
                <p>
                    Campaign Start
                    Date:&nbsp;${this.meta.campaignStartDate
                        ? dayMonthYear(new Date(this.meta.campaignStartDate), "-")
                        : "N/A"}
                </p>
                <p>Promo Code:&nbsp;${this.meta.promoCode ?? "N/A"}</p>
                ${isQuoteTargeted(this.quote)
                    ? html`<div class="flex">
                          <span class="text-peppermint"> ${circleCheckIcon}</span>
                          <p class="pl-1 self-end text-blue font-medium">Demographic targeting applied</p>
                      </div>`
                    : nothing}
            </div>
        </section>`;
    }

    renderClientView() {
        return html`<style>
                @import "main.css";
            </style>
            <div class="pt-4 px-4 block">
                <h1 class="font-bold text-2xl text-peppermint">${this.meta.name}</h1>
                <span class="italic text-sm text-gray-500">Quote ID: ${this.meta.id}</span>
                <h3 class="mt-4 pb-1 text-xl font-medium text-dark-grey">Quote details</h3>
                ${isNetworkDistributed([POSTIES, POBOXES])
                ? html`
                    <div class="flex">
                        <span class="text-danger font-medium">NOTE:</span>
                        <p class="pl-1 self-end text-blue">The mailer's finish size cannot be bigger than A5 when using Postie or PO Box Channels as per the network requirements.</p>
                    </div>`
                : nothing}
                ${this.renderLockedDetails()}
            </div>`;
    }

    copyQuoteIdToClipboard() {
        copyToClipboard(this.meta.id, "Quote ID was copied into your clipboard.");
    }

    render() {
        if (IS_CLIENT_APP) return this.renderClientView();

        return html`
            <style>
                @import "main.css";
            </style>
            <div class="relative border border-dark-grey rounded-sm px-3 py-2 mt-4 flex flex-col justify-between leading-normal">
                ${this.meta.id ? 
                    html`<a
                          href="${URL_CLIENT_VIEW}?${this.meta.id}"
                          target="_blank"
                          class="font-bold text-xl text-peppermint"
                          >${this.meta.name}&nbsp;${linkIcon}</a
                    >`
                    : html`<h1 class="font-bold text-xl">Untitled quote</h1>`}
                ${this.meta.id ?
                    html`<span class="flex items-center text-gray-500">
                            <span class="text-sm font-medium text-danger">Quote ID:</span> 
                            <span class="text-sm pl-1 cursor-pointer hover:underline"
                                @click=${() => this.copyQuoteIdToClipboard()}>
                                ${this.meta.id}
                            </span>
                            <span class="mb-1 pl-1 cursor-pointer text-sm text-peppermint" 
                                @click=${() => this.copyQuoteIdToClipboard()}>
                                ${copyIcon}
                            </span>
                        </span>`
                    : nothing
                }
                ${this.meta.updatedOn ?
                    html`<span class="italic text-sm">Last updated
                          ${dayMonthYear(
                              new Date(this.meta.updatedOn),
                              "-"
                          )}</span>`
                    : nothing}
                ${isQuoteLocked(this.quote)
                    ? this.renderLockedDetails()
                    : html`<section class="flex flex-col pt-2">
                                <div class="my-1">
                                    <div class="flex flex-col">
                                    ${html`
                                        <div
                                            class="flex justify-between mb-2 
                                            ${isDatePast(this.meta.campaignStartDate)
                                                ? "p-2 border-2 border-danger"
                                                : ""} ">
                                            <label class="mr-2" for="save-valid-until">
                                                Campaign Start Date
                                            </label>
                                            <input
                                                type="date"
                                                class="border w-2/5 rounded-sm leading-tight p-1"
                                                .min="${new Date().toISOString().slice(0, 10)}"
                                                .value="${this.meta.campaignStartDate ? 
                                                    new Date(this.meta.campaignStartDate)
                                                        .toISOString()
                                                        .slice(0, 10)
                                                    : null}"
                                                @input=${(e) => store.dispatch(
                                                      quoteMetaUpdate({ campaignStartDate: e.target.value,})
                                                )} />
                                        </div>
                                        <div class="flex justify-between mb-2">
                                            <label class="mr-2" for="promo-code">Promo Code</label>
                                            <input
                                                type="text"
                                                class="border w-2/5 rounded-sm leading-tight p-1"
                                                .value="${this.meta.promoCode ??""}"
                                                @input=${ (e) => store.dispatch(quoteMetaUpdate({ promoCode:e.target.value, })) }
                                                @keydown=${ (e) => {if ("Enter" == e.key) { e.target.blur();}} }
                                            />
                                        </div>
                                    `}
                                    </div>
                                    <label class="block mt-1">
                                        <input
                                            type="checkbox"
                                            class="checkbox align-middle mr-1"
                                            .checked=${this.quote.targeted}
                                            .disabled=${ isQuoteLocked(this.quote) || hasTargetingVariables( this.targetingFilters ) }
                                            @change=${ (e) => this.toggleTargetedValue(e.target.checked) }
                                        />
                                        ${this.quote.targeted ? 
                                            html`<span class="align-middle text-blue font-medium">Demographic targeting applied</span>`
                                            : html`<span class="align-middle">Apply demographic targeting</span>`}
                                    </label>
                                </div>
                                <div class="my-1">
                                    <div class="flex justify-between mb-2">
                                        <label class="mr-2">Weight Bracket</label>
                                        <!-- Display current weight bracket selection or custom selection -->
                                        <span title="Edit weight bracket"
                                            class="align-middle text-blue font-medium cursor-pointer hover:underline" 
                                            @click=${() => this.mailerDetailsDialog()}
                                        >
                                            ${this.quote.customWeightBracket ?
                                                `[${weightBrackets[this.quote.customWeightBracket]}]gms` :
                                                `[${this.weightBracket()}]gms` ?? "N/A"
                                            }
                                            ${penIcon}
                                        </span>
                                    </div>
                                </div>
                            </section>`
                }
                ${isNetworkDistributed([POSTIES, POBOXES])
                ? html`<div class="flex">
                        <span class="text-danger font-medium">NOTE:</span>
                        <p class="pl-1 self-end text-blue">The mailer's finish size cannot be bigger than A5 when using Postie or PO Box Channels as per the network requirements.</p>
                    </div>`
                : nothing}
            </div>
        `;
    }
}

global.customElements.define("rm-quote-details-panel", QuoteDetailsPanel);
