import { OCI__API_REACH_NET_NZ } from "../../config/local";
import store from "../../store";
import { formatDateForApi } from "../dateHelper";
import { plainWarning } from "../notifications/apiWrapper";
import { apiErrorEvent } from "./customEvents";

const networksMap = {
    POSTIES: "A",
    URBAN: "B",
    POBOXES: "C",
    COUNTERS: "C",
    RURAL: "D",
};

export const volumeQuote = async (
    network,
    weightRange,
    volume,
    mailerType,
    enclosed,
    oversized
) => {
    if (volume === 0) return;
    if (!networksMap[network]) return;

    const customerCmsCode = store.getState().quoteMeta.customerCmsCode;
    const campaignStartDate = store.getState().quoteMeta.campaignStartDate;
    const campaignStartDateFormatted = formatDateForApi(campaignStartDate);

    const apiUrl = new URL(`${OCI__API_REACH_NET_NZ}/calculator/v1/volume`);
    // TODO: update to "network" in REST API
    apiUrl.searchParams.append("channel", networksMap[network]);
    apiUrl.searchParams.append("qty", volume);
    apiUrl.searchParams.append("weight_range", Number(weightRange));
    apiUrl.searchParams.append("enclosed_yn", enclosed ? "Y" : "N");
    apiUrl.searchParams.append("oversize_yn", oversized ? "Y" : "N");
    apiUrl.searchParams.append("dist_group", mailerType);
    customerCmsCode
        ? apiUrl.searchParams.append("cms_acct_code", customerCmsCode)
        : null;
    campaignStartDate
        ? apiUrl.searchParams.append("start_date", campaignStartDateFormatted)
        : null;

    try {
        const response = await fetch(apiUrl);

        if (response.ok) {
            const { volumecalc } = await response.json();
            return { amount: Number(volumecalc.amount || 0) };
        }

        document.dispatchEvent(
            apiErrorEvent({
                errorMsg: "Volume calulation failed, please try again.",
            })
        );
    } catch (error) {
        plainWarning("Network issues!", error);
        console.log(error);
    }
};
