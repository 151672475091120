import store from "../../store";

/**
 * Calculate the volume of a particular slice based on each territory network and slice selections as it
 * counts up towards quote summary volumes.
 */
export const sliceVolume = (network, slice) => {
    return store
        .getState()
        .territories.filter(
            (territory) =>
                territory.networkSelections.includes(network) &&
                territory.volumeSelections[network].includes(slice)
        )
        .reduce(
            (acc, territory) => territory.volumesMatrix[network][slice] + acc,
            0
        );
};

/**
 * Checks if the subtotal matrix slice for the given network(s) is non-zero.
 * @param {string[]} networks - An array of network names.
 * @returns {boolean} - True if the subtotal matrix slice for the given network(s) has non zero volume.
 */
export const isNetworkDistributed = (networks) => {
    for (const network of networks) {
        const subtotalMatrix = store.getState().quoteDetails.subtotalMatrix;
        if (subtotalMatrix[network] !== 0) {
            return true;
        }
    }

    return false;
};