import { applyMiddleware, createStore, compose } from "redux";
import subscribeMiddleware from "redux-subscribe";
import root from "./core/reducers";

const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const enhancer = composeEnhancers(applyMiddleware(subscribeMiddleware));

const store = createStore(root, enhancer);

export default store;
